export const DATETIME_FORMAT = "YYYY-MM-DD HH:mm";
export const DATETIME_MILLIS_FORMAT = "YYYY-MM-DD HH:mm:ss SSS";
export const STOPLOSS_COLOR = "#FF8800";
export const TAKE_PROFIT_COLOR = "#AA00CC";
export const LIQUIDATION_COLOR = "#FF0000";
export const MANUAL_COLOR = "#249203";
export const ExecutionEndedStatuses = [
  "ENDED",
  "FAILED",
  "CANCELLED",
  "LIQUIDATED",
];
export const EMPTY_ARRAY = [];
